@media screen and (min-width:922px){  
  #about-container{
      height: max-content;
      margin-top: 50px;
    }
    
    #about-title{
      position:relative;
      top: 5%;
      left: 10%;
      width: 80%;
      font-size: 40px;
      color: white; 
      border-bottom: 5px solid #ffffff71;
      padding-bottom: 5px;
    }
    
    #about-card{
      margin-top: 50px;
      margin-bottom: 30px;
      position: relative;
      top: 5%;
      left: 10%;
      width: 80%;
      align-items: center;
    }

    #about-card-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    
    #about-paragraph{
      width: 300px;
      font-family: 'Montserrat';
      font-size: 20px;
      color: white; 
      text-align: left;
      display:inline-block;
      flex-grow:2;
    }


  #glow-and-gloss{
      color:#65A7FF;
    }
    
    #about-image{
      width: 500px;
      display:inline-block;
      margin-left: calc(5% + 3px);
      justify-content: center;
    }
  }
  @media screen and (max-width:922px) {
    #about-container{
      height: max-content;
      margin-top: 50px;
    }
    
    #about-title{
      position:relative;
      top: 5%;
      left: 10%;
      width: 80%;
      font-size: 40px;
      color: white; 
      border-bottom: 5px solid #ffffff71;
      padding-bottom: 5px;
    }
    
    #about-card{
      margin-top: 50px;
      margin-bottom: 30px;
      position: relative;
      top: 5%;
      left: 10%;
      width: 80%;
      align-items: center;
    }

    #about-card-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    
    #about-paragraph{
      width: 300px;
      font-family: 'Montserrat';
      font-size: 20px;
      color: white; 
      text-align: left;
      display:inline-block;
      flex-grow:2;
    }

  #glow-and-gloss{
      color:#65A7FF;
    }
    
    #about-image{
      width: 400px;
      display:inline-block;
      margin-top: 5%;
      justify-content: center;
    }
  }