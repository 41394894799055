@media screen and (min-width:824px){
  
  #hook-container{
    background-image: url("../imagesrc/homeimage.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
  }
  
  #hook-card{
    position: relative;
    align-self: center;
    left: 8%;
    top: 16%;
    width: 25%;
    min-width: 300px;
    height: min-content;
    font-family: 'Russo One';
    font-size: 55px;
    color: white; 
    border-left: 5px solid #ffffff71; 
    padding-left: 35px;
  }
}

@media screen and (max-width:824px){
  
  #hook-container{
    background-image: url("../imagesrc/homeimage_mobile.png");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
  }
  
  #hook-card{
    position: relative;
    align-self: center;
    left: 8%;
    top: 16%;
    width: 25%;
    min-width: 300px;
    height: min-content;
    font-family: 'Russo One';
    font-size: 55px;
    color: white; 
    border-left: 5px solid #ffffff71; 
    padding-left: 35px;
  }
}