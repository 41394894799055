#header-container{
    position: sticky;
        top: 0px;
        background-color: #031f29;
        height: auto;
        z-index: 10;
        font-size: 18px;
        color: aliceblue;
}

.nav-link{
    color: aliceblue !important;
}
.hover-link:hover, .hover-link:focus, .hover-link:active{
    color: white;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: aliceblue;
}
.App-logo {
    height: 81px;
    margin-left: 10px;
    pointer-events: none;
}

.nav-link{
    margin-left: 20px; 
    margin-right: 20px;
}

.hover-link::after{
    content: '';
    height: 2px; 
    width: 100%; 
    background:aliceblue;
    display: block;
    opacity: 0; 
    transition: all 0.2s; 
    pointer-events: none;
}

.hover-link:hover::after{
    opacity: 1; 
}

#nav-body{
    margin-right: 80px;
}

#book-now-button{
    color: #031f29 !important; 
    background-color: rgb(105, 155, 215);
    border-radius:8px;
    padding: 10px;
    bottom: 65px;
    text-align: center;
}

#hamburger-container{
    color: aliceblue;
}
#hamburger-button{
    color: transparent !important;
    border-color: transparent;
    margin-right: 30px;
}
@media screen and (max-width: 991px){
    .nav-link{
        width: max-content !important;
        align-self: center;
    }
    #nav-body{
        margin-right: 0px;
    }
}