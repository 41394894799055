  #packages-container{
    height: auto;
    margin-top: 100px;
  }
  
  #packages-title{
    position:relative;
    text-align: center;
    top: 5%;
    left: 10%;
    width: 80%;
    font-size: 40px;
    color: white; 
    border-bottom: 5px solid #ffffff71;
    padding-bottom: 5px;
  }
  
  #packages-card{
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-basis: 200px;
  }
  .flex-column{
    flex: 300px;
  }

  .package-name{
    width: 100%;
    text-align: center;
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
    font-style: italic;
  }
  
  .package-image { 
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    margin-bottom: 20px;
  }

  .package-description{
    width: 100%;
    text-align: left;
    color: white;
    font-size: 20px;
    font-family: 'Montserrat';
    
  }

  .add-ons{
    margin-top: 4vw;
    margin-left: 7vw;
  }

  #add-ons-title{
    width: 100%;
    text-align: left;
    color: white;
    font-size: 25px;
    font-family: 'Russo One';
  }

  #add-ons-list{
    font-family: 'Montserrat';
    font-size: 20px;
  }