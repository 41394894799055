    #contact-container{
        height: max-content;
        margin-top: 50px;
    }

    #contact-title{
        position:relative;
        text-align: center;
        top: 5%;
        left: 10%;
        width: 80%;
        font-size: 40px;
        color: white; 
        border-bottom: 5px solid #ffffff71;
        padding-bottom: 5px;
    }

    #contact-card{
        margin-top: 50px;
        margin-bottom: 50px;
        position: relative;
        top: 5%;
        left: 10%;
        width: 80%;
        align-items: center;
    }

      #contact-card-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    #contact-paragraph{
        width: 300px;
        font-family: 'Montserrat';
        font-size: 18px;
        color: white; 
        text-align: left;
        display:inline-block;
        flex-basis: 350px;
        flex-grow:2;
        list-style-type:none;
    }
    
    #google-form{
        width: 300px;
        height: 400px;
        display:inline-block;
        flex-grow:2;
        flex-basis: 600px;
    }

    #tiktok-icon{
       margin-top: 3px; 
       width: 60px;
    }
    
    #instagram-icon{
        margin-top: 5px;
        margin-left: 8px;
        width: 45px;
    }
